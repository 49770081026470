import React, { useEffect, useRef } from "react";
import { useParams } from "react-router";

declare var location: any;

const Middle = () => {
  const { id: cart } = useParams<{ id: any }>();

  const ref = useRef(false);
  useEffect(() => {
    if (ref.current === false) {
      ref.current = true;
      location.href = location.href.replace("cart", "payment");
    }
  }, [cart]);
  return <></>;
};

export default Middle;
